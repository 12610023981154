import * as React from 'react';
import {navigate} from 'gatsby';
import {RouteComponentProps} from '@reach/router';

const Index: React.FC<RouteComponentProps> = ({location}) => {
  React.useEffect(() => {
    navigate(`${location?.pathname?.replace(/\/$/, '')}/app`);
  }, [location]);
  return null;
};

export default Index;
